<template>
  <v-tabs v-model="tabsNavigation" show-arrows>
    <v-tab>Session</v-tab>
    <v-tab>Exercises</v-tab>

    <v-tabs-items v-model="tabsNavigation" class="pa-5">
      <v-tab-item>
        <dl>
          <dt>Id</dt>
          <dd>{{ item.id }}</dd>

          <dt>Unique key</dt>
          <dd>{{ item.uniqueKey }}</dd>

          <dt>Name</dt>
          <dd>{{ item.name }}</dd>

          <dt>Category</dt>
          <dd>{{ item.category }}</dd>

          <dt>Type</dt>
          <dd>{{ item.type }}</dd>

          <dt>Level</dt>
          <dd>{{ item.level }}</dd>

          <dt>Unlock at</dt>
          <dd>{{ item.unlockAt }}</dd>
        </dl>
      </v-tab-item>

      <v-tab-item>
        <div
          v-for="(exercise, exerciseIndex) in item.exercises"
          :key="`exercise-${exerciseIndex}`"
          class="mb-10"
        >
          <h3>
            Exercise {{ exerciseIndex + 1 }}/{{ item.exercises.length }}
          </h3>
          <v-divider class="mb-5"></v-divider>

          <exercise-details-tab-content :exercise="exercise" />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { ref } from 'vue'
import ExerciseDetailsTabContent from '@/components/ExerciseDetailsTabContent.vue'

export default {
  components: {
    ExerciseDetailsTabContent,
  },

  props: {
    item: {
      required: true,
      type: Object,
    },
  },

  setup() {
    const tabsNavigation = ref(0)

    return {
      tabsNavigation,
    }
  },
}
</script>
