<template>
  <dl>
    <dt>Id</dt>
    <dd>{{ exercise.id }}</dd>

    <dt>Unique key</dt>
    <dd>{{ exercise.uniqueKey }}</dd>

    <dt>Name</dt>
    <dd>{{ exercise.name }}</dd>

    <dt>Category</dt>
    <dd>{{ exercise.category }}</dd>

    <dt>Time</dt>
    <dd>{{ exercise.time }}m</dd>

    <dt>Countdown time</dt>
    <dd>{{ exercise.countdownTime }}s</dd>

    <dt>Tags</dt>
    <dd>
      <v-chip
        v-for="(tag, index) in exercise.tags"
        :key="'tag-' + index"
        small
      >
        {{ tag }}
      </v-chip>
    </dd>

    <dt>Search tags</dt>
    <dd>
      <v-chip
        v-for="(searchTag, index) in exercise.searchTags"
        :key="'searchTag-' + index"
        small
      >
        {{ searchTag }}
      </v-chip>
    </dd>

    <dt>Thumbnails</dt>
    <dd class="d-flex">
      <v-img-ex
        :src="exercise.thumbnail"
        class="mr-5"
        max-width="160"
        max-height="160"
        v-on="$listeners"
      />
      <v-img-ex
        :src="exercise.thumbnailFemale"
        max-width="160"
        max-height="160"
        v-on="$listeners"
      />
    </dd>
  </dl>
</template>

<script>
import VImgEx from '@/components/VImgEx.vue'

export default {
  components: {
    VImgEx,
  },

  props: {
    exercise: {
      required: true,
      type: Object,
    },
  },

  setup() {},
}
</script>
