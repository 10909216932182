<template>
  <v-img v-if="$attrs.src && $attrs.src.length"
         :src="$attrs.src"
         v-bind="$attrs"
         class="cursor-pointer"
         contain
         @click="openOverlay($attrs.src)"
  >
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
import useImageLightBox from '@/composables/imageLightBox'

export default {
  setup() {
    const { openOverlay } = useImageLightBox()

    return {
      openOverlay,
    }
  },
}
</script>
